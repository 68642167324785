<template>
  <div id="app">
    <!-- 顶部 -->
    <div class="log">
      <div
        style="
          width: 1200px;
          height: 100%;
          margin: 0 auto;
          display: flex;
          align-items: flex-end;
          box-sizing: border-box;
        "
      >
        <img src="@/assets/logoText.png" alt="" />
      </div>
    </div>
    <div class="tab">
      <div
        style="
          width: 1200px;
          margin: 0 auto;
          display: flex;
          align-items: center;
          height: 100%;
        "
      >
        <div
          class="tab-box"
          v-for="(item, index) in tabData"
          :key="index"
          @click="jump(item)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
    <!-- 路由页面 -->
    <router-view />
    <div style="margin: 0 auto; width: 1200px;">
      <div class="slicing"></div>

      <div class="bottomtab">
        <span @click="jump(tabData[0])">关于我们</span>
        <span style="margin: 0 15px" @click="jump(tabData[0])">新闻资讯</span>
        <span @click="jump(tabData[0])">联系我们</span>
      </div>

      <!-- <div class="codeInfo">
        <img src="@/assets/gzh.jpg" alt="">
        <span style="margin: 0 86px 0px 30px">扫码关注丽瑾公众号</span>
        <img src="@/assets/wx.jpg" style="margin-right: 64px;width: 213px !important;height: 213px !important" alt="">
        <span>扫码进入丽瑾商城</span>
      </div> -->

      <div class="webInfo">苏ICP备2023053806号-1</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      tabData: [
        {
          title: "首页",
          route: "/",
          type: null,
        },
        {
          title: "国内",
          route: "/newsList",
          type: 1,
        },
        {
          title: "社会",
          route: "/newsList",
          type: 2,
        },
        {
          title: "财经",
          route: "/newsList",
          type: 3,
        },
        {
          title: "科技",
          route: "/newsList",
          type: 4,
        },
        {
          title: "体育",
          route: "/newsList",
          type: 5,
        },
        {
          title: "汽车",
          route: "/newsList",
          type: 6,
        },
      ],
    };
  },
  methods: {
    jump(data) {
      if (data.type != null) {
        this.$router.push({
          path: data.route,
          query: {
            page: data.type,
          },
        });
      } else {
        this.$router.push({
          path: data.route,
        });
      }
    },
  },
};
</script>
<style lang="scss">
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
}

.log {
  height: 80px;
  width: 100%;
  img {
    height: 68px;
    width: 192px;
  }
}
.tab {
  height: 70px;
  width: 100%;
  background: rgba(25, 114, 222, 1);
  margin-bottom: 30px;

  .tab-box {
    color: white;
    font-size: 20px;
    font-weight: bold;
    margin-right: 65px;
    white-space: nowrap;
    cursor: pointer;
  }
}

.slicing {
  margin: 0 auto;
  width: 1200px;
  height: 2px;
  opacity: 1;
  background: #DDDDDD;
  margin-bottom: 22px;
}

.bottomtab {
  margin: 0 auto;
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 70px;
  cursor: pointer;
  span {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
  }
}

.codeInfo {
  margin: 0 auto;
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 239px;
    height: 239px;
  }
}
.webInfo {
  margin: 0 auto;
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999999;
  padding-bottom: 20px;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NewsList from '../views/newsList/newsList.vue'
import ArticleDetail from '../views/articleDetail/articleDetail.vue'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/newsList',
    name: 'newsList',
    component: NewsList
  },
  {
    path: '/articleDetail',
    name: 'articleDetail',
    component: ArticleDetail
  }
]

const router = new VueRouter({
  routes
})




export default router

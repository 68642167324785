<template>
  <div class="newsPage">
    <!-- <div class="crumb">
      <span @click="jump()">首页</span>
      <img src="@/assets/right.png" alt="">
      <span>{{ pageTitle }}</span>
    </div> -->

    <div class="topLine">{{pageTitle}}</div>

    <div class="newsList" v-for="(item, index) in newsData" :key="index" @click="jumpArticle(item.id)" style="cursor: pointer;">
      <!-- <img :src="imgurl + item.img" alt="" /> -->
      <div class="content">
        <div class="roud"></div>

        <div class="title">{{item.title}}</div>

        <div class="time" style="margin-left: auto">{{item.createTime}}</div>
      </div>
      <div class="body">
        {{item.littleValue}}
      </div>
    </div>
    <div class="paging">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNum"
        :page-sizes="[10,20,30,40]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getNewsListApi } from '@/http/api/api'
export default {
  data() {
    return {
      query: {
        pageSize: 10,
        pageNum: 1,
        type: null
      },
      total: 0,
      page: null,
      newsData: [],
      imgurl: 'https://fuhey.cn/prod-api'
    }
  },
  created() {
    if (this.$route.query.page == null || this.$route.query.page == undefined) {
      this.$router.push('/')
    } else {
      this.query.type = this.$route.query.page
      this.getNewsList()
    }
    if (this.$route.query.page == 1) {
      this.pageTitle = '国内'
    }
    if (this.$route.query.page == 2) {
      this.pageTitle = '社会'
    }
    if (this.$route.query.page == 3) {
      this.pageTitle = '财经'
    }
    if (this.$route.query.page == 4) {
      this.pageTitle = '科学'
    }
    if (this.$route.query.page == 5) {
      this.pageTitle = '体育'
    }
    if (this.$route.query.page == 6) {
      this.pageTitle = '汽车'
    }
  },
  watch: {
    $route() {
      location.reload()
    }
  },
  methods: {
    getNewsList() {
      getNewsListApi(this.query).then(response => {
        this.newsData = response.data.data
        this.total = response.data.total
      })
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getNewsList()
    },
    handleCurrentChange(val) {
      this.query.pageNum = val
      this.getNewsList()
    },
    jumpArticle(id) {
      this.$router.push({
        path: '/articleDetail',
        query: {
          id: id
        }
      })
    },
    jump() {
      this.$router.push('/')
    },
  }
};
</script>

<style lang="scss" scoped>
.newsPage {
  margin: 0 auto;
  width: 1200px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.crumb {
  width: 1200px;
  height: 30px;
  img { 
    width: 13px;
    height: 11px;
    margin: 0 10px;
  }
  span {
    cursor: pointer;
  }
}

.topLine {
  width: 1200px;
  opacity: 1;
  background: rgba(238, 238, 238, 1);
  margin-bottom: 31px;
  padding: 5px 0px 5px 6px;
  font-size: 20px;
  font-weight: bold;
  color: #1972DE;
}

.newsList {
  width: 1200px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px dashed #e6e6e6;
  .roud {
    width: 10px;
    height: 10px;
    border-radius: 25px;
    background: rgba(238, 238, 238, 1);
    margin-right: 8px;
  }
  .content {
    width: 100%;
    display: flex;
    align-items: center;
  }
  img {
    width: 250px;
    height: 150px;
    margin-right: 30px;
  }

  .title {
    width: 900px;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .time {
    font-size: 16px;
    color: #333333;
  }

  .body {
    width: 921px;
    height: 40px;
    font-size: 16px;
    margin-top: 10px;
    color: #999999;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    margin-left: 18px;
    margin-top: 24px;
  }
}

.newsList:hover {
  .roud {
    background: #1972DE;
  }
  .title {
    color:#1972DE
  }
}

.paging {
  width: 1200px;
  display: flex;
  justify-content: center;
  margin-bottom: 55px;
}

// 组件样式

::v-deep .el-pager li.active {
  background: #1972DE !important;
}
</style>
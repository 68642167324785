import serviceAxios from "../index";

// 首页行业新闻
export const getNewsListApi = (data) => {
    return serviceAxios({
        url: "/sapi/xyadver/" + data.type + '/?pageSize=' + data.pageSize + '&pageNum=' + data.pageNum,
        method: "get"
    });
};


export const getArticleDetailApi = (data) => {
    return serviceAxios({
        url: '/sapi/xyadver/get/' + data.id,
        method: 'get'
    })
}

export const getCarouselApi = () => {
    return serviceAxios({
        url: '/sapi/xyadver/img/1',
        method: 'get'
    })
}

export const getRecommendNewsApi = () => {
    return serviceAxios({
        url: '/sapi/xyadver/all',
        method: 'get'
    })
}
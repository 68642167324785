<template>
    <div class="newsPage">
      <!-- <div class="crumb">
        <span @click="jump()">首页</span>
        <img src="@/assets/right.png" alt="">
        <span @click="jumpTab()">{{ pageTitle }}</span>
        <img src="@/assets/right.png" alt="">
        <span>正文</span>
      </div>
  
      <div class="topLine"></div> -->

      <div class="title" >
        {{articleDetailData.title}}
      </div>

      

      <div class="info" style="margin-bottom: 24px">
        <!-- <span style="margin-right: 10px">来源：中华网</span> -->
        <span>时间：{{articleDetailData.createTime}}</span>
      </div>

      <div class="topLine"></div>

      <div class="content" v-html="articleDetailData.value">
      </div>
    </div>
  </template>
  
  <script>
  import { getArticleDetailApi } from '@/http/api/api'
  export default {
    data() {
      return {
        query: {

          id: null
        },
        total: 0,
        articleDetailData: {}
      }
    },
    created() {
      if (this.$route.query.id == null || this.$route.query.id == undefined) {
        this.$router.push(-1)
      } else {
        this.query.id = this.$route.query.id
        this.getArticleDetail()
      }
    },
    watch: {
      $route() {
        location.reload()
      }
    },
    methods: {
      getArticleDetail() {
        getArticleDetailApi(this.query).then(response => {
          this.articleDetailData = response.data
          // if (response.data.allType == 1) {
          //   this.pageTitle = '新闻'
          // }
          // if (response.data.allType == 2) {
          //   this.pageTitle = '资讯'
          // }
          // if (response.data.allType == 3) {
          //   this.pageTitle = '财经'
          // }
          // if (response.data.allType == 4) {
          //   this.pageTitle = '汽车'
          // }
        })
      }, 
      jump() {
        this.$router.push('/')
      },
      jumpTab() {
        this.$router.push({
          path: '/newsList',
          query: {
            page: this.articleDetailData.allType
          }
        })
      },
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .newsPage {
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  
  .crumb {
    width: 1200px;
    height: 30px;
    img { 
      width: 13px;
      height: 11px;
      margin: 0 10px;
    }
    span {
      cursor: pointer;
    }
  }
  
  .topLine {
    width: 1200px;
    height: 2px;
    border-bottom: 1px dashed rgba(221, 221, 221, 1);
    opacity: 1;
    margin-bottom: 31px;
  }
  
  .title {
    width: 1200px;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 24px;
  }

  .info {
    width: 1200px;
    font-size: 16px;
    color: #333333;
  }

  .content {
    width: 1200px;
    margin-bottom: 30px;
    white-space: pre-wrap;
    tab-size: 4;

  }
  ::v-deep p {
    img { width: 100% !important;}
  }
  </style>
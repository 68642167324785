<template>
  <div class="home">
    <div style="width: 1200px">
      <el-row :gutter="40" style="margin-bottom: 60px">
        <el-col :span="8">
          <el-carousel
            trigger="click"
            height="210px"
            indicator-position="outside"
            style="margin-bottom: 20px"
          >
            <el-carousel-item
              v-for="(item, index) in carouselData"
              :key="index"
            >

              <div class="carousel" style="cursor: pointer;" @click="jumpArticle(item.imgHref)">
                <img
                  style="width: 100%; height: 210px"
                  :src="imgurl + item.value"
                  alt=""
                />
                <div class="carousel-title">
                  {{item.remark}}
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>

          <div class="upperLeft" style="cursor: pointer;" @click="jumpArticle(reconmmendDataB.id)">
            <img :src="imgurl + reconmmendDataB.img" alt="">
            <img class="upperLeft-img" src="@/assets/upperLeft.png" alt="">
            <div class="upperLeft-content">
              <span class="upperLeft-content-title">
                {{reconmmendDataB.title}}
              </span>
              <span class="upperLeft-content-body">
                {{reconmmendDataB.littleValue}}
              </span>
            </div>
          </div>
        </el-col>
        <!-- 今日推荐 -->
        <el-col :span="8">
          <div class="recommend">
            <div style="display: flex;">
              <div class="recommend-tag">
                今日推荐
              </div>
            </div>


            <div class="recommend-oneNews" style="cursor: pointer;" @click="jumpArticle(reconmmendData.id)">
              <span class="recommend-oneNews-title">
                {{reconmmendData.title}}
              </span>

              <span class="recommend-oneNews-content">
                {{reconmmendData.littleValue}}
              </span>
            </div>
            
            <div class="recommend-newsList">
              <div class="recommend-newsList-box">
                <div class="recommend-newsList-box-oneNews" style="cursor: pointer;" @click="jumpArticle(reconmmendCountryOne.id)">
                  <img src="@/assets/society.png" alt="">
                  <span>{{reconmmendCountryOne.littleValue}}</span>
                </div>
                <div class="recommend-newsList-box-basic" v-for="(item,index) in reconmmendCountryData" :key="index" style="cursor: pointer;" @click="jumpArticle(item.id)">
                  <div class="recommend-newsList-box-basic-round"></div>
                  <span>{{item.littleValue}}</span>
                </div>
              </div>

              <div class="recommend-newsList-box">
                <div class="recommend-newsList-box-oneNews" style="cursor: pointer;" @click="jumpArticle(reconmmendSocietyOne.id)">
                  <img src="@/assets/domestic.png" alt="">
                  <span>{{reconmmendSocietyOne.littleValue}}</span>
                </div>
                <div class="recommend-newsList-box-basic" v-for="(item,index) in reconmmendSocietyData" :key="index" style="cursor: pointer;" @click="jumpArticle(item.id)">
                  <div class="recommend-newsList-box-basic-round"></div>
                  <span>{{item.littleValue}}</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>



        <el-col :span="8">
          <div class="rollNews">
            <div style="display: flex;">
              <div class="rollNews-tag">
                滚动新闻
              </div>
            </div>

            <div class="rollNews-list" v-for="(item, index) in rollData" :key="index" style="cursor: pointer;" @click="jumpArticle(item.id)">
              {{item.littleValue}}
            </div>
          </div>
        </el-col>
      </el-row>



      <el-row :gutter="40" style="margin-bottom: 66px">
        <el-col :span="8">
          <div class="basicBox">
            <div class="basicBox-title">
              国内
              <img @click="jumpTab(1)" src="@/assets/add.png" alt="">
            </div>

            <div class="basicBox-list" v-for="(item,index) in countryData" :key="index" @click="jumpArticle(item.id)">
              <div class="basicBox-list-roud"></div>
              <span>{{ item.littleValue ? item.littleValue : ''}}</span>
            </div>
          </div>
        </el-col>
        
        <el-col :span="8">
          <div class="basicBox">
            <div class="basicBox-title">
              社会
              <img @click="jumpTab(2)" src="@/assets/add.png" alt="">
            </div>

            <div class="basicBox-list" v-for="(item,index) in societyData" :key="index" @click="jumpArticle(item.id)">
              <div class="basicBox-list-roud"></div>
              <span>{{ item.littleValue }}</span>
            </div>
          </div>
        </el-col>

        <el-col :span="8">
          <div class="basicBox">
            <div class="basicBox-title">
              财经
              <img @click="jumpTab(3)" src="@/assets/add.png" alt="">
            </div>

            <div class="basicBox-list" v-for="(item,index) in financeData" :key="index" @click="jumpArticle(item.id)">
              <div class="basicBox-list-roud"></div>
              <span>{{ item.littleValue }}</span>
            </div>
          </div>
        </el-col>
      </el-row>



      <el-row :gutter="40" style="margin-bottom: 86px">
        <el-col :span="8">
          <div class="basicBox">
            <div class="basicBox-title">
              科技
              <img @click="jumpTab(4)" src="@/assets/add.png" alt="">
            </div>

            <div class="basicBox-list" v-for="(item,index) in scienceData" :key="index" @click="jumpArticle(item.id)">
              <div class="basicBox-list-roud"></div>
              <span>{{ item.littleValue }}</span>
            </div>
          </div>
        </el-col>
        
        <el-col :span="8">
          <div class="basicBox">
            <div class="basicBox-title">
              体育
              <img @click="jumpTab(5)" src="@/assets/add.png" alt="">
            </div>

            <div class="basicBox-list" v-for="(item,index) in sportData" :key="index" @click="jumpArticle(item.id)">
              <div class="basicBox-list-roud"></div>
              <span>{{ item.littleValue }}</span>
            </div>
          </div>
        </el-col>

        <el-col :span="8">
          <div class="basicBox">
            <div class="basicBox-title">
              汽车
              <img @click="jumpTab(6)" src="@/assets/add.png" alt="">
            </div>

            <div class="basicBox-list" v-for="(item,index) in carData" :key="index" @click="jumpArticle(item.id)">
              <div class="basicBox-list-roud"></div>
              <span>{{ item.littleValue }}</span>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getNewsListApi, getCarouselApi, getRecommendNewsApi } from "../http/api/api";
export default {
  name: "HomeView",
  data() {
    return {
      query: {
        type: 0,
      },
      countryData: [],
      societyData: [],
      financeData: [],
      scienceData: [],
      sportData: [],
      carData: [],
      rollData: [],

      reconmmendData: {},
      reconmmendDataB: {},
      reconmmendCountryData: [],
      reconmmendCountryOne: {},
      reconmmendSocietyData: [],
      reconmmendSocietyOne: [],
      imgurl: "https://fuhey.cn/prod-api",
      carouselData: [],
    };
  },
  created() {
    // 国内
    this.getNewsList(1);
    // 社会
    this.getNewsList(2);
    // 财经
    this.getNewsList(3);
    // 科技
    this.getNewsList(4);
    // 体育
    this.getNewsList(5);
    // 汽车
    this.getNewsList(6);
    // 滚动新闻
    this.getNewsList(7);
    // 轮播图
    this.getCarousel();

    this.getREcommendNes(1)
    
    this.getREcommendNes(2)

    this.getREcommendNes(3)
  },
  methods: {
    getREcommendNes(type) {
      getRecommendNewsApi().then(response => {
        if (type == 1) {
          this.reconmmendData = response.data[0]
          if (response.data.length > 1) {
            this.reconmmendDataB = response.data[1]
          }

        }
        if (type == 2) {
         if (response.data.length > 1) {
            this.reconmmendCountryOne = response.data[0]
            for (let i = 1; i < response.data.length; i++) {
              this.reconmmendCountryData.push(response.data[i])
              if (i > 1) {
                break
              }
            }
          } else if (response.data.length > 0) {
            this.reconmmendCountryOne = response.data[0]
          }
        }
        if (type == 3) {
          if (response.data.length > 1) {
            this.reconmmendSocietyOne = response.data[0]
            for (let i = 1; i < response.data.length; i++) {
              this.reconmmendSocietyData.push(response.data[i])
              if (i > 1) {
                break
              }
            }
          } else if (response.data.length > 0) {
            this.reconmmendSocietyOne = response.data[0]
          }
        }
      })
    },
    getCarousel() {
      getCarouselApi().then((response) => {
        this.carouselData = response.data;
      });
    },
    getNewsList(type) {
      let data = {
        type: type,
        pageNum: 1,
        pageSize: 10,
      };
      getNewsListApi(data).then((response) => {
        if (type == 1) {
          this.countryData = response.data.data
        }
        if (type == 2) {
          this.societyData = response.data.data
        }
        if (type == 3) {
          this.financeData = response.data.data
        }
        if (type == 4) {
          this.scienceData = response.data.data
        }
        if (type == 5) {
          this.sportData = response.data.data
        }
        if (type == 6) {
          this.carData = response.data.data
        }
        if (type == 7) {
          this.rollData = response.data.data
        }
      });
    },
    jumpTab(page) {
      this.$router.push({
        path: "/newsList",
        query: {
          page,
        },
      });
    },
    jumpArticle(id) {
      this.$router.push({
        path: "/articleDetail",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  margin: 0 auto;
  width: 1200px;
}

.carousel {
  height: 210px;
  position: relative;
  .carousel-title {
    padding: 2px 41px;
    box-sizing: border-box;
    position: absolute;
    height: 30px;
    width: 100%;
    background: rgba(0, 0, 0, 0.60);
    left: 0;
    bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    color: white
  }
}

.upperLeft {
  height: 100px;
  display: flex;
  position: relative;
  background: rgba(245, 245, 245, 1);
  img {
    width: 140px;
    height: 100px;
  }

  .upperLeft-img {
    position: absolute;
    right: 0;
    top: 0;
    width: 54px;
    height: 50px;
  }

  .upperLeft-content {
    box-sizing: border-box;
    padding: 6px 0px 6px 8px;
  }
  .upperLeft-content-title, .upperLeft-content-body {
    width: 220px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
  }
  .upperLeft-content-title {
    width: 190px;
    margin-bottom: 5px;
    font-size: 16px;
    color: #333333;
    font-weight: bold;
  }
  .upperLeft-content-body {
    font-size: 14px;
    color: #999999;
  }
}

.recommend {
  border-top: 1px solid #D6D6D6;
  height: 362px;
  display: flex;
  flex-direction: column;
  
  .recommend-tag {
    padding: 7px 0px;
    border-top: 2px solid #1972DE;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
  }

  .recommend-oneNews {
    padding-bottom: 12px;
    border-bottom: 1px dashed rgba(214, 214, 214, 1);
    margin-bottom: 20px;
    .recommend-oneNews-title {
      display: block;
      font-size: 16px;
      color: #1972DE;
      font-weight: bold;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 20px;
    }
    .recommend-oneNews-content {
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      font-size: 14px;
      color: rgba(153, 153, 153, 1);

    }
  }

  .recommend-newsList {
    height: 100%;
    .recommend-newsList-box {
      height: 50%;
      .recommend-newsList-box-oneNews {
        display: flex;
        align-items: center;
        margin-bottom: 21px;
        img {
          width: 45px;
          height: 20px;
        }
        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-left: 3px;
          font-size: 16px;
          color: #333333;
          font-weight: 400;
        }
      }
      .recommend-newsList-box-oneNews:hover {
        span { color: #1972DE; }
      }

      .recommend-newsList-box-basic {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 8px;
        margin-bottom: 7px;
        .recommend-newsList-box-basic-round {
          width: 10px;
          height: 10px;
          background: rgba(221, 221, 221, 1);
          border-radius: 25px;
        }
        span {
          width: 340px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-left: 3px;
          font-size: 16px;
          color: #333333;
          font-weight: 300;
        }
      }

      .recommend-newsList-box-basic:hover {
        .recommend-newsList-box-basic-round {
          background: #1972DE;
        }
        span {
          color: #1972DE;
        }
      }
    }

  }
}

.rollNews {
  border-top: 1px solid #D6D6D6;
  height: 362px;
  display: flex;
  flex-direction: column;
  .rollNews-tag {
    padding: 7px 0px;
    border-top: 2px solid #1972DE;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
  }

  .rollNews-list {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 20px 0;
    border-bottom: 1px dashed rgba(214, 214, 214, 1);
    font-size: 16px;
    color: #333333;
  }
  .rollNews-list:hover {
    color: #1972DE;
  }
}

.basicBox {
  .basicBox-title {
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    padding: 0px 0px 5px 3px;
    border-bottom: 2px solid #1972DE;
    margin-bottom: 17px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
  .basicBox-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 17px;
    cursor: pointer;
    .basicBox-list-roud {
      width: 10px;
      height: 10px;
      border-radius: 25px;
      background: rgba(221, 221, 221, 1);
      
    }
    span {
      width: 346px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #333333;
    }
  }

  .basicBox-list:hover {
    color: #1972DE;
    .basicBox-list-roud {
      background: rgba(25, 114, 222, 1);
    }
  }
}

// 组件样式
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

::v-deep .el-carousel__indicator,
.el-carousel__indicator--horizontal {
  button {
    background: rgba(25, 114, 222, 1) !important;
    width: 8px;
    height: 8px;
    border-radius: 4px 4px 4px 4px;
  }
}

::v-deep .el-carousel__indicator.is-active button {
  background: rgba(25, 114, 222, 1) !important;
    width: 37px;
    height: 8px;
    border-radius: 4px 4px 4px 4px;
}



</style>
